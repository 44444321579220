import FingerprintJS from "@fingerprintjs/fingerprintjs";
const CryptoJS = require("crypto-js");

export function isMobile() {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );
}
export function randomStr(length, chars) {
  length = length || 6;
  chars =
    chars || "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
  var result = "";
  for (var i = length; i > 0; --i) {
    result += chars[Math.floor(Math.random() * chars.length)];
  }
  return result;
}

export const getDeviceFingerprint = async () => {
  const fpPromise = await FingerprintJS.load();
  const result = await fpPromise.get();
  const fingerprintId = result.visitorId;
  return fingerprintId;
};

export var getCanvasFp = function (options) {
  options = options ? options : {};
  var result = [];
  var canvas = document.createElement("canvas");
  canvas.width = 2000;
  canvas.height = 200;
  canvas.style.display = "inline";
  var ctx = canvas.getContext("2d");
  ctx.rect(0, 0, 10, 10);
  ctx.rect(2, 2, 6, 6);
  result.push(
    "canvas winding:" +
      (ctx.isPointInPath(5, 5, "evenodd") === false ? "yes" : "no")
  );

  ctx.textBaseline = "alphabetic";
  ctx.fillStyle = "#f60";
  ctx.fillRect(125, 1, 62, 20);
  ctx.fillStyle = "#069";
  if (options.dontUseFakeFontInCanvas) {
    ctx.font = "11pt Arial";
  } else {
    ctx.font = "11pt no-real-font-123";
  }
  ctx.fillText("Cwm fjordbank glyphs vext quiz, \ud83d\ude03", 2, 15);
  ctx.fillStyle = "rgba(102, 204, 0, 0.2)";
  ctx.font = "18pt Arial";
  ctx.fillText("Cwm fjordbank glyphs vext quiz, \ud83d\ude03", 4, 45);

  ctx.globalCompositeOperation = "multiply";
  ctx.fillStyle = "rgb(255,0,255)";
  ctx.beginPath();
  ctx.arc(50, 50, 50, 0, Math.PI * 2, true);
  ctx.closePath();
  ctx.fill();
  ctx.fillStyle = "rgb(0,255,255)";
  ctx.beginPath();
  ctx.arc(100, 50, 50, 0, Math.PI * 2, true);
  ctx.closePath();
  ctx.fill();
  ctx.fillStyle = "rgb(255,255,0)";
  ctx.beginPath();
  ctx.arc(75, 100, 50, 0, Math.PI * 2, true);
  ctx.closePath();
  ctx.fill();
  ctx.fillStyle = "rgb(255,0,255)";
  ctx.arc(75, 75, 75, 0, Math.PI * 2, true);
  ctx.arc(75, 75, 25, 0, Math.PI * 2, true);
  ctx.fill("evenodd");

  if (canvas.toDataURL) {
    result.push("canvas fp:" + canvas.toDataURL());
  }

  let fingerPrintRawData = result[1];
  let fingerPrintHash = CryptoJS.MD5(fingerPrintRawData).words.join("_");
  return fingerPrintHash;
};

export function validatePhoneNumber(phoneNumber) {
  const regex = /^(13|14|15|16|17|18|19)\d{9}$/;
  return regex.test(phoneNumber);
}

export function validateCode(phoneNumber) {
  const regex = /^\d{4}$/;
  console.error(phoneNumber, regex.test(phoneNumber))
  return regex.test(phoneNumber);
}
