import { defineComponent, onMounted, ref } from "vue";
import { ElButton, ElInput, ElMessage } from "element-plus";
import { validateCode, validatePhoneNumber } from "@/util";
import { xhr } from "@/util/http";
import { useRouter } from "vue-router";
import "./loginPage.scss";

export default defineComponent({
  setup() {
    const phone = ref();
    const code = ref();
    const setup = ref(1);
    const router = useRouter();

    const sendSms = async () => {
      if (!validatePhoneNumber(phone.value)) {
        return ElMessage.error("手机号格式不正确");
      }
      try {
        const { flag } = await xhr.post("/sms/send", {
          phone: Number(phone.value),
        });
        if (!flag) {
          return ElMessage.error("验证码发送失败");
        } else {
          setup.value = 2;
        }
      } catch (e) {
        return Promise.reject({ message: "服务器出差了，请稍后重试~" });
      }
    };

    const login = async () => {
      if (!(await checkCode())) {
        ElMessage.success("登录成功");
        localStorage.setItem("____ai_phone____", phone.value);
        router.push("/home");
      }
    };

    onMounted(() => {
      const val = localStorage.getItem("____ai_phone____");
      val && router.push("/home");
    });

    const checkCode = async () => {
      if (!validateCode(Number(code.value))) {
        return ElMessage.error("验证码格式不正确");
      }
      try {
        const { flag } = await xhr.post("/sms/validateCode", {
          phone: Number(phone.value),
          code: Number(code.value),
        });
        if (!flag) {
          return ElMessage.error("验证码错误");
        }
      } catch (e) {
        return Promise.reject({ message: "服务器出差了，请稍后重试~" });
      }
    };

    return () => {
      return (
        <div className="container">
          <div className="form">
            {setup.value === 1 && (
              <>
                <div className="form-item">
                  <ElInput
                    maxlength={11}
                    prefix-icon="Iphone"
                    class="form-input"
                    v-model={phone.value}
                    placeholder="请填写手机号"
                  />
                </div>
                <div className="form-item">
                  <ElButton
                    type="primary"
                    plain
                    onClick={sendSms}
                    class="form-code"
                  >
                    发送验证码
                  </ElButton>
                </div>
              </>
            )}
            {setup.value === 2 && (
              <>
                <div className="form-item">
                  <ElInput
                    maxlength={4}
                    prefix-icon="Comment"
                    class="form-input"
                    v-model={code.value}
                    placeholder="请填写验证码"
                  />
                </div>
                <div className="form-item">
                  <el-button class="form-btn" type="primary" onClick={login}>
                    登录
                  </el-button>
                </div>
              </>
            )}
          </div>
        </div>
      );
    };
  },
});
