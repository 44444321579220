import axios from "axios";

const baseDomain =
  process.env.NODE_ENV === "production"
    ? "//api.cccccl.com/api/v1/"
    : "//127.0.0.1:7001/api/v1/";

const xhr = axios.create({
  baseURL: baseDomain,
  method: "post",
  headers: {},
});

const requestResolve = async (config) => {
  return config;
};

const requestReject = (error) => {
  return Promise.reject(error);
};

const responseResolve = (response) => {
  if (response.status === 200) {
    const { code, result } = response.data;
    if (code === 0) {
      return Promise.resolve(result); // 返回成功状态的 Promise
    } else {
      return Promise.reject({ message: "服务器出差了，请稍后重试~" }); // 返回失败状态的 Promise
    }
  } else {
    return Promise.reject({ message: "服务器出差了，请稍后重试~" }); // 返回失败状态的 Promise
  }
};

const responseReject = () => {
  return Promise.reject({ message: "服务器出差了，请稍后重试~" }); // 返回失败状态的 Promise
};

xhr.interceptors.request.use(requestResolve, requestReject);
xhr.interceptors.response.use(responseResolve, responseReject);

const eventSource = (params) => {
  params = new URLSearchParams(params)
  return new EventSource(`${baseDomain}ai/chat?${params.toString()}`);
};

export { xhr, eventSource };
