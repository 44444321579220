import { defineComponent, nextTick, onMounted, ref, watch } from "vue";
import "./homePage.css";
import { ElButton, ElIcon, ElInput, ElMessage, ElSkeleton } from "element-plus";
import { isMobile } from "@/util";
import { randomStr } from "../util";
import { eventSource, xhr } from "@/util/http";

const _isMobile = isMobile();

export default defineComponent({
  setup() {
    const phone = ref("");
    const sessionId = randomStr(10);
    const isLoading = ref(true);
    const msgLock = ref(false);
    const msgList = ref([]);
    const inputValue = ref("");
    const contentRef = ref(null);

    onMounted(() => {
      const val = localStorage.getItem("____ai_phone____");
      phone.value = val;
      getMsgList();
      setTimeout(() => {
        isLoading.value = false;
      }, 1000);
    });

    watch([msgList], () => {
      handleScroll();
    });

    const handleScroll = () => {
      if (contentRef.value) {
        contentRef.value.scrollTop = contentRef.value.scrollHeight;
      }
    };

    const getMsgList = async () => {
      let list = await xhr.get("/ai/getMsgList", {
        params: {
          phone: Number(phone.value),
        },
      });
      if (list.length) {
        list = list.map((item) => {
          return {
            identity: item.identity,
            content: item.identity === "1" ? item.prompt : item.answer,
          };
        });
      }
      msgList.value = [
        ...[{ content: "您好，请问有什么可以帮助您？", identity: "2" }],
        ...list,
      ];
    };

    const triggerEventSource = () => {
      if (!inputValue.value) {
        return;
      }
      const es = eventSource({
        phone: phone.value,
        prompt: inputValue.value,
        sessionId,
      });

      const handleTimeout = () => {
        ElMessage.error("连接超时请重试");
        msgLock.value = false;
        es.close();
        msgList.value = msgList.value.map((msg, index) => {
          if (index === msgList.value.length - 1) {
            return { ...msg, content: "连接超时，请重试.." };
          }
          return msg;
        });
      };
      const timer = setTimeout(handleTimeout, 10000);
      es.onmessage = (event) => {
        clearTimeout(timer);
        let data = event.data || "";
        if (data === "[DONE]") {
          es.close();
          msgLock.value = false;
          return;
        }
        data = data.replaceAll(/\\n/g, "<br/>");
        const obj = JSON.parse(data);
        const { content, role } = obj.choices[0].delta;

        if (!role && !content) {
          es.close();
          msgLock.value = false;
          return;
        }
        msgList.value = msgList.value.map((msg, index) => {
          if (index === msgList.value.length - 1) {
            return { ...msg, content: msg.content + content };
          }
          return msg;
        });
      };
      es.onerror = () => {
        es.close();
      };
      inputValue.value = "";
      return () => {
        es.close();
      };
    };

    const sendMsg = () => {
      if (!inputValue.value) {
        return;
      }
      msgLock.value = true;
      msgList.value = [
        ...msgList.value,
        {
          content: inputValue.value,
          identity: "1",
        },
        {
          content: "",
          identity: "2",
        },
      ];
      triggerEventSource();
      nextTick(() => {
        handleScroll();
      });
    };

    const renderMsgList = () => {
      return msgList.value.map((e, index) => {
        return (
          <div
            className="item"
            key={index}
            style={{ marginBottom: _isMobile ? "15px" : "25px" }}
          >
            <ElIcon className="avatar">
              {e.identity === "1" ? <UserFilled /> : <Grid />}
            </ElIcon>
            <div className="content">
              {e.content ? (
                <div v-html={e.content}></div>
              ) : (
                <div class="loader">
                  <div class="loader-inner ball-pulse">
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                </div>
              )}
            </div>
          </div>
        );
      });
    };

    const renderContainer = () => {
      return (
        <>
          <div className="list" ref={contentRef}>
            {renderMsgList()}
          </div>
          <div className="bottom">
            <ElInput
              size="small"
              maxlength={1000}
              className="textarea"
              v-model={inputValue.value}
              autosize={{ minRows: 1, maxRows: 20 }}
              placeholder="想问啥，我都知道..."
              type="textarea"
              resize="none"
            />
            <ElButton
              disabled={!inputValue.value.trim() || msgLock.value}
              class="button"
              type="primary"
              onClick={sendMsg}
            >
              发送
            </ElButton>
          </div>
        </>
      );
    };

    return () => {
      return (
        <div className="container">
          {isLoading.value ? (
            <ElSkeleton class="skeleton" rows={30} animated />
          ) : (
            renderContainer()
          )}
        </div>
      );
    };
  },
});
